export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20')
];

export const server_loads = [];

export const dictionary = {
		"/(main)": [6,[3]],
		"/(main)/accrecs_home": [8,[3]],
		"/(main)/accrecs": [7,[3]],
		"/(main)/contacts_home": [11,[3]],
		"/(main)/contacts": [10,[3]],
		"/(main)/contact": [9,[3]],
		"/(ctoken)/ctoken": [5,[2]],
		"/(main)/geolocations": [12,[3]],
		"/(main)/integrations": [13,[3]],
		"/(main)/inventory_home": [14,[3]],
		"/(main)/invoice": [15,[3]],
		"/(main)/models": [17,[3]],
		"/(main)/model": [16,[3]],
		"/(pos)/pos": [20,[4]],
		"/(main)/preferences": [18,[3]],
		"/(main)/setshare": [19,[3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';